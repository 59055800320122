<template>
	<Row class="join-webinar-section">
		<Container>
			<div class="w-full max-w-[740px] mx-auto">
				<Heading
					tag="h2"
					type="big"
					class="text-white lg:text-center mb-[16px] leading-tight"
					text="Start Your Trading Journey Today in Our Next Live Masterclass"
				/>
				<Paragraph
					class="text-white lg:text-center mb-[32px] lg:mb-[62px] lg:px-[64px]"
				>
					The AI Boom
					<i>“minted 600,000 new millionaires”</i>
					last year. The market is HOT, making right now the best time in history to
					start trading stocks! But this window of opportunity won’t be open for
					long. Don’t miss out. Join the next Live Masterclass to learn from seasoned
					pros!
				</Paragraph>
			</div>

			<div
				class="wistia-video"
				style="background-image: url('/images/sykes_video_thumbnail.webp')"
			>
				<div id="youtube-player-2" />
				<button
					v-if="!playVideo"
					class="play-btn"
					@click="onYouTubeIframeAPIReady"
				>
					<svg
						width="169"
						height="169"
						viewBox="0 0 169 169"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="84.5645"
							cy="84.1023"
							r="83.9826"
							fill="url(#paint0_radial_1196_7910)"
						/>
						<defs>
							<radialGradient
								id="paint0_radial_1196_7910"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(84.5645 84.1023) rotate(90) scale(90.7907)"
							>
								<stop
									stop-color="white"
									stop-opacity="0"
								/>
								<stop
									offset="1"
									stop-color="#C5DCFF"
								/>
							</radialGradient>
						</defs>
					</svg>
					<img
						src="/images/icons/play-arrow.svg"
						width="116"
						height="116"
						alt="play video"
					/>
				</button>
			</div>

			<CtaButton
				to="https://mil.pub/live-challenge-ts"
				class="mx-auto text-center mb-6"
				:blank="true"
				dataCtaType="live-masterclass-webinar"
			>
				Show Me The Profit Window!
			</CtaButton>

			<img
				src="/images/assets/as-sees-on-desktop.webp"
				alt="as-sees-on-desktop.webp"
				width="1124"
				height="210"
				class="hidden md:block mx-auto"
			/>

			<div class="flex flex-col md:hidden">
				<img
					src="/images/assets/as-sees-on-mobile-1.webp"
					alt="as-sees-on-desktop.webp"
					width="352"
					height="64"
					class="block mx-auto"
				/>
				<img
					src="/images/assets/as-sees-on-mobile-2.webp"
					alt="as-sees-on-desktop.webp"
					width="352"
					height="64"
					class="block mx-auto mb-[16px]"
				/>
			</div>
		</Container>
	</Row>
	<Head>
		<Style>#youtube-player-2 { width: 560px; height: 315px; }</Style>
	</Head>
</template>

<script setup>
const playVideo = ref(false);

function onYouTubeIframeAPIReady() {
	if (
		!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')
	) {
		const script = document.createElement("script");
		script.setAttribute("src", "https://www.youtube.com/iframe_api");
		script.setAttribute("async", true);
		document.head.appendChild(script);
	}

	setTimeout(() => {
		playVideo.value = true;
		player = new YT.Player("youtube-player-2", {
			videoId: "VCtwp1oOW8I", // Replace with your video's ID
			playerVars: {
				autoplay: true, // Autoplay is turned off
				controls: 1, // Show video controls
				modestbranding: 1, // Hide the YouTube logo
				rel: 0 // Don't show related videos
			}
		});
	}, 500);
}
</script>

<style lang="scss">
@import "../assets/utils.scss";

.join-webinar-section {
	background-color: black;
	overflow: hidden;
}

.wistia-video {
	width: 100%;
	max-height: 300px;
	min-height: 260px;
	max-width: 520px;
	height: 100%;
	display: block;
	position: relative;
	background-color: $primary-50;
	margin: auto;
	margin-bottom: 32px;

	background-size: cover;
	background-position: center;

	@include breakpoint(md) {
		max-height: 520px;
		min-height: 520px;
		max-width: 960px;
	}
	@include breakpoint(xl) {
	}
	@media (min-width: 1360px) {
		max-width: none;
		max-height: 672px;
		min-height: 672px;
	}
}

.play-btn {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		position: absolute;
		content: "";
		height: 52px;
		width: 52px;
		background-color: white;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	svg {
		position: absolute;
		margin: auto;
		top: 0;
		left: -25%;
		right: -25%;
		bottom: 0;
		z-index: 0;
		animation: blink 2s infinite;
	}

	img {
		position: relative;
		z-index: 1;
	}
}

@keyframes blink {
	0% {
		opacity: 0.5;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

iframe#youtube-player-2 {
	width: 100%;
	height: 100%;
}
</style>
