<template>
	<Row class="hero-section">
		<Container class="xl:grid grid-cols-2">
			<div class="flex flex-col gap-4">
				<h1 class="hero-title">
					<span class="highlight-yellow">Master the Patterns Behind</span>
					Winning Trades
				</h1>

				<!-- <Heading
					tag="h4"
					class="text-xl text-white"
					text="Get a watchlist built by our $12.6 million brain trust FREE by signing up here!"
				/> -->
				<Heading
					tag="h4"
					class="text-xl text-white"
					text="Learn the Strategies That Have Taken Traders From Humble Beginnings to Life-Changing Wins!"
				/>

				<div ref="scriptContainer">
					<NuxtLink
						type="link"
						:to="`https://mil.pub/live-challenge-ts`"
						target="_blank"
						class="wj-embed-button !inline-flex mr-auto"
						data-webinarHash="10m29iy1"
						data-cta-type="hero-live-challenge"
					>
						Join Tim's Winning Team!
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14 4.92993L12.5 6.42993L17.0703 11.0002H3V13.0002H17.0703L12.5 17.5706L14 19.0706L21.0703 12.0002L14 4.92993Z"
								:fill="`${type === 'secondary' ? 'white' : '#1E293B'} `"
							/>
						</svg>
					</NuxtLink>
				</div>
				<!-- <LeadCaptureForm id="hero-section-form" /> -->

				<JoinPitch />
			</div>

			<div class="video-section">
				<div
					class="video"
					:style="`${
						!playVideo
							? `background-image: url('/images/sykes_video_thumbnail.webp')`
							: `background-color:black`
					}`"
				>
					<div id="youtube-player" />
					<span
						v-if="!playVideo"
						class="chart-overlay"
					>
						<img
							src="/images/chart-graph.svg"
							width="175"
							height="89"
							alt="chart-snippet-pic"
						/>
					</span>
					<span
						v-if="!playVideo"
						class="tim-snippet author"
					>
						<img
							src="/images/tim-snippet-pic.webp"
							width="49"
							loading="lazy"
							alt="tim-snippet-pic"
						/>
						<div>
							<Paragraph class="text-sm lg:text-lg">
								TimAlert for LIFW -
								<br class="hidden lg:block" />
								<b>Long at $0.26</b>
							</Paragraph>
						</div>
					</span>
					<span
						v-if="!playVideo"
						class="tim-snippet community"
					>
						<div class="relative flex">
							<img
								src="/images/user-group-2.webp"
								loading="lazy"
								width="200"
								height="62"
								alt="image-4"
							/>
						</div>
						<div>
							<Paragraph class="text-sm lg:text-lg">
								Unlock trade ideas
								<br class="none lg:block" />
								from the community
							</Paragraph>
						</div>
					</span>
					<button
						v-if="!playVideo"
						data-element="hero-section-playvideo"
						class="play-btn"
						@click="onYouTubeIframeAPIReady"
					>
						<svg
							width="169"
							height="169"
							viewBox="0 0 169 169"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								cx="84.5645"
								cy="84.1023"
								r="83.9826"
								fill="url(#paint0_radial_1196_7910)"
							/>
							<defs>
								<radialGradient
									id="paint0_radial_1196_7910"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(84.5645 84.1023) rotate(90) scale(90.7907)"
								>
									<stop
										stop-color="white"
										stop-opacity="0"
									/>
									<stop
										offset="1"
										stop-color="#C5DCFF"
									/>
								</radialGradient>
							</defs>
						</svg>
						<img
							src="/images/icons/play-arrow.svg"
							width="116"
							height="116"
							alt="play video"
						/>
					</button>
				</div>
			</div>
		</Container>
		<Container class="hidden md:block">
			<img
				width="1200"
				height="106"
				src="/images/as-featured-hero.svg"
				alt="as-featured-hero image"
				class="w-full block mx-auto mt-[32px] lg:mt-[64px]"
			/>
		</Container>
		<Container class="as-featured-mobile-block md:hidden">
			<img
				width="1200"
				height="106"
				src="/images/as-featured-hero.svg"
				alt="as-featured-hero image"
				class="w-full block mt-[20px] mb-[32px]"
			/>
		</Container>
	</Row>
</template>

<script setup>
const playVideo = ref(false);
const scriptContainer = ref();
const player = ref();
function onPlayerReady() {
	// Perform actions when the player is ready, e.g., play the video
	player.value.playVideo();
	// Set any other necessary states or actions here
}

function onYouTubeIframeAPIReady() {
	try {
		if (
			!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')
		) {
			const script = document.createElement("script");
			script.setAttribute("src", "https://www.youtube.com/iframe_api");
			script.setAttribute("async", true);
			document.head.appendChild(script);
		}

		setTimeout(() => {
			playVideo.value = true;
			player.value = new YT.Player("youtube-player", {
				videoId: "jL5wB6DhdBM", // Replace with your video's ID
				playerVars: {
					autoplay: true, // Autoplay is turned off
					controls: 1, // Show video controls
					modestbranding: 1, // Hide the YouTube logo
					rel: 0 // Don't show related videos
				},
				events: {
					onReady: onPlayerReady
				}
			});
		}, 500);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log("Error in Video injection: ", e);
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.as-featured-mobile-block {
	overflow: auto;
	margin-bottom: -16px !important;

	img {
		min-width: fit-content;
	}
}
#youtube-player {
	width: 560px;
	height: 315px;
}

@keyframes blink {
	0% {
		opacity: 0.5;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

.video {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 17.649px;
	background: $neutral-50;
	box-shadow: 0px 11.45905px 57.29527px 6.87543px rgba(0, 0, 0, 0.25);
	height: 100%;
	position: relative;
	background-size: cover;
	background-position: center;
	.chart-overlay {
		border-radius: 8px;
		background: white;
		box-shadow: 0px 2.29181px 10.31315px 1.14591px rgba(0, 0, 0, 0.12);
		position: absolute;
		right: -64px;
		display: none;

		@include breakpoint(lg) {
			display: block;
		}
	}

	iframe {
		min-height: 420px;
	}

	.tim-snippet {
		border-radius: 229.181px;
		background: white;
		box-shadow: 0px 2.29181px 10.31315px 1.14591px rgba(0, 0, 0, 0.12);
		// width: 396.483px;
		// height: 115.736px;
		position: absolute;
		padding: 8px 24px 8px 8px;
		display: flex;
		gap: 16px;
		align-items: center;

		&.author {
			max-width: 248px;
			left: -12px;
			top: -24px;

			@include breakpoint(lg) {
				max-width: unset;
				left: -72px;
				top: -32px;
			}
		}

		&.community {
			background-color: $primary-300;

			bottom: -64px;

			img {
				position: relative;
				height: 40px;
				width: auto;
				border-radius: 32px;

				&:not(:first-child) {
					margin-left: -16px;
				}

				@include breakpoint(lg) {
					max-width: unset;
					height: 62px;
				}
			}
		}

		img {
			height: 50px;
		}

		@include breakpoint(lg) {
			max-width: unset;
		}
	}

	.play-btn {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;

		&::before {
			position: absolute;
			content: "";
			height: 52px;
			width: 52px;
			background-color: white;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
		svg {
			position: absolute;
			margin: auto;
			top: 0;
			left: -25%;
			right: -25%;
			bottom: 0;
			z-index: 0;
			animation: blink 2s infinite;
		}

		img {
			position: relative;
			z-index: 1;
		}
	}
	width: 100%;

	@include breakpoint(lg) {
		max-height: 480px;
		min-height: 480px;
	}

	@media (min-width: 1200px) {
		right: -96px;
		width: 600.167px;
		max-height: 340px;
		min-height: 340px;
		right: 0;
	}
	@include breakpoint(xxl) {
		right: -96px;
	}
}

.video-section {
	padding-top: 32px;
	padding-bottom: 32px;
	margin-top: 32px;

	@include breakpoint(md) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@include breakpoint(xl) {
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.hero-title {
	color: $primary-50;
	font-family: Fira Sans;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: 111%;

	@include breakpoint(lg) {
		font-size: 58px;
		letter-spacing: -0.25px;
	}
}

.form-watchlist-cta {
	display: flex;
	width: 100%;
	padding: 6px;
	align-items: center;
	border-radius: 8px;
	background: $primary-50;
	justify-content: space-between;
	flex-direction: column;

	@include breakpoint(md) {
		width: 500px;
		flex-direction: row;
		gap: 10px;
	}
	@include breakpoint(lg) {
		width: 500px;
	}

	input {
		background-color: transparent;
		color: $neutral-800;
		padding: 10px 12px;
		font-size: 18px;
		font-weight: 400;
		line-height: 160%;
		width: 100%;

		&::placeholder {
			font-weight: 400;
			color: $neutral-400;
		}

		@include breakpoint(lg) {
			padding: 10px;
		}
	}
}

.hero-section {
	background-color: $primary-900;
	position: relative;
	padding-top: 96px;
	padding-bottom: 0;
	margin-top: -60px;
	width: 100%;
	overflow: hidden;
	display: block;

	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		right: 0;
		width: 100%;
		height: 55%;
		background-image: url("../../images/background-image.webp");
		background-repeat: no-repeat;
		background-size: cover;
		mix-blend-mode: multiply;
		background-position: bottom;
		opacity: 0.2;
		z-index: -1;
		bottom: 0;
		@include breakpoint(xl) {
			top: 0;

			width: 50%;
			height: 100%;
			mix-blend-mode: multiply;
			background-position: left;
		}
	}
	@include breakpoint(md) {
		padding-bottom: 32px;
	}
	@include breakpoint(xl) {
		padding-top: 140px;
	}

	.container {
		max-width: 900px;
		margin: auto;
	}

	@media (min-width: 1200px) {
		.container {
			max-width: 1200px;
		}
	}
}

.image-container {
	display: flex;

	img {
		position: relative;

		&:not(:first-child) {
			margin-left: -16px;
		}
	}
}

.join-pitch {
	display: flex;
	align-items: center;
	gap: 16px;

	span {
		color: $neutral-50;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		opacity: 0.5;
	}
}
</style>
